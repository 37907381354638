import axios from '@axios';

const baseUrl = '/profile';

export default {
  getProfile() {
    return axios.get(`${baseUrl}`);
  },
  updateProfile(profile) {
    return axios.put(`${baseUrl}`, profile);
  },
  changeProfilePassword(oldPassword, newPassword) {
    return axios.put(`${baseUrl}/password`, {
      new_password: newPassword,
      new_password_confirm: newPassword,
      old_password: oldPassword,
    });
  },
  uploadProfilePicture(file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${baseUrl}/picture`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};
